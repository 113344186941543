import { useState, useEffect, useContext, useRef } from 'react';
import parse from 'html-react-parser';
import { Modal } from 'bootstrap';
import { CartContext } from '../store/CartContext';

export default function ValidationModal() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const validationModalEl = useRef();
  const [validationModal, setValidationModal] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    setValidationModal(
      new Modal(validationModalEl.current, {
        keyboard: false,
        backdrop: 'static',
      })
    );

    setShowErrorModal(cartState.validation.showErrors);
  }, [cartState.validation]);

  useEffect(() => {
    if (showErrorModal) {
      validationModal.show();
    }
  }, [showErrorModal, validationModal]);

  const handleModalClose = () => {
    cartDispatch({
      type: 'VALIDATION',
      payload: {
        ...cartState.validation,
        showErrors: false,
        formTouched: true,
      },
    });
  };

  const errorMsgs = Object.values(cartState.validation.errors)?.map((err, index) => (
    <li className="mb-2" key={index}>
      {parse(err)}
    </li>
  ));

  return (
    <div className="modal fade text-start" ref={validationModalEl} id="validation-modal" tabIndex="-1" aria-hidden="true">
      <div className="validation-modal modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body p-4 p-md-4">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleModalClose}
            ></button>
            <h3 className="modal-title text-danger fs-5 fw-600 mb-3">Please fix the following issues:</h3>
            <ul className="mb-0 ps-3">{errorMsgs}</ul>
            <p className="text-end mt-4 mb-0">
              <button
                type="button"
                className="btn btn-primary px-5 fw-600"
                data-bs-dismiss="modal"
                onClick={handleModalClose}
              >
                Okay
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
