import { Helmet } from 'react-helmet-async';

export default function CartSkeleton() {
  return (
    <>
      <Helmet>
        <title>Cart Details</title>
      </Helmet>

      <div className="container-fluid but-fixed cart-skeleton">
        <div className="row justify-content-center">
          <div className="col-12 pt-3 pt-md-4">
            <div id="items-header" className="row d-none d-md-flex justify-content-between mb-1 px-3 fw-bold text-muted">
              <div className="product-details">Product Name</div>
              <div className="product-qty product-qty-label text-center">Quantity</div>
              <div className="product-price text-end">Price</div>
            </div>

            <div className="card product mb-2">
              <div className="card-body d-flex flex-wrap justify-content-between py-2 py-md-3 px-0">
                <div className="product-details">
                  <h3 className="product-info h4">
                    <span className="animated-placeholder w-md-80 height-lg"></span>
                  </h3>
                  <h6 className="small my-3 mb-1">
                    <span className="animated-placeholder w-50 w-md-30 mb-1"></span>
                  </h6>
                </div>
                <div className="product-qty text-center ms-auto ms-lg-0">
                  <span className="animated-placeholder height-lg"></span>
                </div>
                <div className="product-price text-end">
                  <span className="price-display">
                    <span className="animated-placeholder w-70 height-lg"></span>
                  </span>
                </div>
              </div>
            </div>
            <div className="card product mb-2">
              <div className="card-body d-flex flex-wrap justify-content-between py-2 py-md-3 px-0">
                <div className="product-details">
                  <h3 className="product-info h4">
                    <span className="animated-placeholder w-md-80 height-lg"></span>
                  </h3>
                  <h6 className="small my-3 mb-1">
                    <span className="animated-placeholder w-50 w-md-30 mb-1"></span>
                  </h6>
                </div>
                <div className="product-qty text-center ms-auto ms-lg-0">
                  <span className="animated-placeholder height-lg"></span>
                </div>
                <div className="product-price text-end">
                  <span className="price-display">
                    <span className="animated-placeholder w-70 height-lg"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-lg-8 pe-lg-4"></div>

          <aside className="col-sm-10 col-md-8 col-lg-4 mt-3 mb-5">
            <div className="card card-summary cart-summary mb-3 rounded-0 border-0 border-top border-bottom">
              <div className="card-body p-0">
                <ul className="list-group list-group-flush m-0">
                  <li className="list-group-item subtotal px-3">
                    <div className="item-details order-tally">
                      <span className="animated-placeholder w-70"></span>
                    </div>
                    <div className="item-amount text-end w-100">
                      <span className="animated-placeholder w-50"></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div id="promo-code-wrap" className="form-inline d-flex my-3">
              <input id="promo-code-input" className="form-control" type="text" placeholder="Promo Code" disabled />
              <button id="promo-code-btn" className="btn btn-outline-secondary" type="button" disabled>
                Apply
              </button>
            </div> */}
            <a href="/checkout/" className="checkout-btn btn btn-lg btn-success w-100 mt-2 disabled" disabled>
              Checkout Now
            </a>
          </aside>
        </div>
      </div>
    </>
  );
}
