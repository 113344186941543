import cookies from '../utils/cookies';
import Cart1 from './cart/v1';
import Cart2 from './cart/v2';

const CartWrapper = () => {
  const useCartV2 = cookies.get('testVariant') === 'useCartV2';

  if (useCartV2) {
    return <Cart2 />;
  }

  return <Cart1 />;
};

// the index page is the cart...
export default CartWrapper;
