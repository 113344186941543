// the domain key is the trigger if we can show modal
// aside from the domain we also check the products in the cart\
// if domain and productIds = show the modal upon user exit intent

export const standardDigitalCertMap = {
  default: {
    name: 'Standard Digital Delivery',
    description: 'Certificate processed and available for download within 5 days of course completion.',
  },
  '16e6dc13-35be-46e4-9625-08dabcee2708': {
    name: 'Standard Digital Delivery',
    description: 'Certificate processed and available for download within 5 days of course completion.',
  },
  'beef961e-5244-47c8-905c-08dcc1506fac': {
    name: '30-Day Certificate Print/Download',
    description: 'Includes 30-day access to print/download certificate.',
  },
};
