import { useContext } from 'react';
import { BrandContext } from '../store/BrandContext';

export default function useBrand() {
  const context = useContext(BrandContext);
  // console.log("context", context);
  if (context === undefined) {
    throw new Error('useBrand must be used within a BrandProvider');
  }
  return context;
}
