// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'txAdultDriverASC', // this used as the field name as well as the unique identifier
    productIds: [
      'bcabc100-3f77-4b90-8115-e234f72bb980', // Texas Adult Driver Education
    ],
    errorMsg: `You must attest that you read and understand the <strong>Texas Adult Driver Education</strong> course enrollment contract by typing your <strong>full legal name</strong> in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'signature', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      To purchase the <strong>Texas Adult Driver Education</strong> course, the student must electronically sign their{' '}
      <strong>full legal name</strong> in the box below to attest that the student has read, understand, and agrees to the
      attached{' '}
      <a
        href="https://cdn.certus.com/global/pdf/tx-ad-enrollment-contract-asc.pdf"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        enrollment contract
      </a>{' '}
      and the student is over 18 years of age at the time of purchase. A copy of the electronically signed contract will be
      delivered to the student at the beginning of the course.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
