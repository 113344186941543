import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useBrand from '../hooks/useBrand';
import CartProvider from '../store/CartProvider';
import cookies from '../utils/cookies';
import Header from './Header';
import Footer from './Footer';
import ExitModal from './ExitModal';
import ErrorModal from './ErrorModal';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Layout.propTypes = {
  children: PropTypes.node,
};

export default function Layout({ children }) {
  const { domain, cssFileUrl } = useBrand();

  useEffect(() => {
    const isTestMode = cookies.get('unpublished') === '1' ? true : false;
    if (isTestMode) {
      document.body.classList.add('test-mode');
    }
  }, []);

  return (
    <CartProvider>
      <HelmetProvider>
        <Helmet>{domain && cssFileUrl ? <link href={cssFileUrl} rel="stylesheet"></link> : null}</Helmet>

        <Header />

        <main id="content-wrap">{children ?? <Outlet />}</main>

        <Footer />

        <ExitModal />

        <ErrorModal />
      </HelmetProvider>
    </CartProvider>
  );
}
