import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
FieldItem.propTypes = {
  attestItem: PropTypes.object.isRequired,
  isSingle: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func,
  fieldType: PropTypes.string.isRequired,
};

export default function FieldItem({ attestItem, isSingle, handleModalClose, fieldType }) {
  // there is a bug with modal being duplicated if we dont set it up like this
  // AttestItemContent() is a component itself
  const AttestItemContent = () => {
    const attestModalEl = useRef();
    const [modal, setModal] = useState(null);

    useEffect(() => {
      if (attestItem.ModalContent()) {
        setModal(new Modal(attestModalEl.current));
      }
    }, []);

    const handleModal = (e) => {
      e.preventDefault();
      modal.show();
    };

    const handleCloseIAgreeModal = (e) => {
      e.preventDefault();
      modal.hide();
      if (handleModalClose) {
        attestModalEl.current.addEventListener('hidden.bs.modal', () => {
          handleModalClose(fieldType);
        });
      }
    };

    return (
      <>
        <attestItem.Blurb handleModal={handleModal} />

        {attestItem.ModalContent() && (
          <div
            className="modal fade text-start"
            ref={attestModalEl}
            id={`${attestItem.name}-modal`}
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="attestation-modal modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body fw-400 p-4 p-md-4">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                  <attestItem.ModalContent />

                  <p className="text-end mt-5 mb-0">
                    {isSingle && fieldType === 'iAgree' ? (
                      <button
                        type="button"
                        className="btn btn-primary px-5 fw-600"
                        // data-bs-dismiss="modal"
                        onClick={handleCloseIAgreeModal}
                      >
                        I Agree
                      </button>
                    ) : (
                      <button type="button" className="btn btn-primary px-5 fw-600" data-bs-dismiss="modal">
                        Close
                      </button>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isSingle ? (
        <AttestItemContent />
      ) : (
        <li className="mb-2">
          <AttestItemContent />
        </li>
      )}
    </>
  );
}
