import { useState, useEffect, useRef, useContext } from 'react';
import { Collapse } from 'bootstrap';
import cookies from '../../utils/cookies';
import SelectedUpsells from '../SelectedUpsells';
import { CartContext } from '../../store/CartContext';
import UpsellModal from '..//UpsellModal';
import UpsellGroups from './UpsellGroups';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellDisplay.propTypes = {
  isSingle: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
};

export default function UpsellDisplay({ isSingle, item }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const [isUpsellCollapsed, setIsUpsellCollapsed] = useState(true);
  const [collapse, setCollapse] = useState(null);

  const upsellCollapse = useRef();

  // this should be set/coming from Convert, this is what will trigger the upsell modal
  const useUpsellModal = cookies.get('testVariant') === 'useUpsellModal';
  // const useUpsellModal = true; // for testing only!!!

  // checking if any of the upsells is selected
  const isAnUpsellSelected = item.upSellProducts.length ? item.upSellProducts.some((upsell) => upsell.isSelected) : true;

  useEffect(() => {
    setCollapse(
      new Collapse(upsellCollapse.current, {
        toggle: false,
      })
    );

    // using 'show.bs.collapse' because the effect is immediate
    upsellCollapse.current.addEventListener('show.bs.collapse', () => {
      setIsUpsellCollapsed(false);
    });
    // using 'hidden.bs.collapse' because the effect is after it collapses
    upsellCollapse.current.addEventListener('hidden.bs.collapse', () => {
      setIsUpsellCollapsed(true);
    });
  }, []);

  useEffect(() => {
    if (!isSingle || isAnUpsellSelected || (isSingle && !useUpsellModal)) {
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          viewedUpsells: true,
        },
      });
    }
  }, [cartDispatch, isSingle, isAnUpsellSelected, useUpsellModal]);

  const toggleCollapse = () => {
    collapse.toggle();

    // track clicked state
    if (!cartState.userExperience.viewedUpsells) {
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          viewedUpsells: true,
        },
      });
    }
  };

  return (
    <>
      {(!isSingle || useUpsellModal) && (
        <>
          <div className="mt-0">
            <button
              className={`btn-toggle upsell-toggle text-start align-items-start small fw-600 ${
                isUpsellCollapsed ? '' : 'is-expanded'
              }`}
              onClick={toggleCollapse}
            >
              {/* <span className="fs-5 lh-1 align-middle me-1">{isUpsellCollapsed ? <>&#43;</> : <>&#8722;</>} </span> */}
              <span className="align-middle">
                {isUpsellCollapsed ? (
                  <>
                    View<span className="d-none d-sm-inline">/Edit</span>
                  </>
                ) : (
                  'Hide'
                )}{' '}
                Course Upgrades & Add-ons
              </span>
            </button>
          </div>

          {isUpsellCollapsed && <SelectedUpsells item={item} />}
        </>
      )}

      <div
        className={`upsells-wrapper ${(!isSingle || useUpsellModal) && 'collapse'}`}
        // className={`upsells-wrapper mt-3 ${!isSingle && 'collapse'}`}
        // className={`upsells-wrapper mt-3`}
        ref={upsellCollapse}
        id={`upsells-collapse-${item.cart_ProductId}`}
      >
        <UpsellGroups item={item} />
      </div>

      {isSingle && useUpsellModal && <UpsellModal item={item} />}
    </>
  );
}
