import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import cookies from '../../utils/cookies';
import Error from '../../components/ErrorBoundary';
import CartItem from '../../components/v2/CartItem';
import OrderSummary from '../../components/v2/OrderSummary';
import CheckoutBtn from '../../components/v2/CheckoutBtn';
import CartSkeleton from '../../components/v2/CartSkeleton';
import CartEmpty from '../../components/CartEmpty';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';
import useBrand from '../../hooks/useBrand';
import ValidationModal from '../../components/ValidationModal';
import AttestationForm from '../../components/AttestationForm';
import RelatedUpsells from '../../components/v2/RelatedUpsells';
import ToastNotification from '../../components/v2/ToastNotification';
import { Collapse } from 'bootstrap';
import FeeDescriptionModal from '../../components/FeeDescriptionModal';

const Cart = () => {
  const { domain } = useBrand();
  const [searchParams, setSearchParams] = useSearchParams();
  const { cartId, productId, tp, coupon, groupId, host, bulk, testVariant } = Object.fromEntries([...searchParams]);
  const myCartId = cartId || cookies.get('cartId');
  const bulkCartId = cookies.get('bulkCartId');

  const [cartState, cartDispatch] = useContext(CartContext);

  const isSingle = cartState?.cartProducts?.length === 1;

  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const [isCartLoaded, setIsCartLoaded] = useState(false);

  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
  const [collapse, setCollapse] = useState(null);

  const descriptionCollapse = useRef();

  // adding current-page-{pageName} class for styling the header breadcrumb section
  useEffect(() => {
    document.body.classList.add('current-page-cart');
  }, []);

  // setting testVariant for testing
  useEffect(() => {
    if (testVariant) {
      cookies.set('testVariant', testVariant);
    }
  }, [testVariant]);

  useEffect(() => {
    if (
      isSingle &&
      cartState.cartProducts[0]?.upSellProducts.length !== 0 &&
      cartState.cartProducts[0].upsellType !== 'Replace'
    ) {
      setCollapse(
        new Collapse(descriptionCollapse.current, {
          toggle: false,
        })
      );

      // using 'show.bs.collapse' because the effect is immediate
      descriptionCollapse.current.addEventListener('show.bs.collapse', () => {
        setIsDescriptionCollapsed(false);
      });
      // using 'hidden.bs.collapse' because the effect is after it collapses
      descriptionCollapse.current.addEventListener('hide.bs.collapse', () => {
        setIsDescriptionCollapsed(true);
      });
    }
  }, [isSingle, cartState.cartProducts]);

  const storeCartData = useCallback(
    (cartData) => {
      let cart = cartData;

      // console.log('cart', cart);

      // this sets and updates the local cart store making it available for all the components
      cartDispatch({
        type: 'UPDATE',
        payload: {
          ...cart,
          isLoading: false,
          isPageShowing: false,
        },
      });

      cookies.set('cartId', cart.cartId, 7);

      if (bulkCartId !== cart.cartId) {
        bulkCartId && cookies.delete('bulkCartId');
      }
      if (bulk === '1') {
        cookies.set('bulkCartId', cart.cartId, 7);
      }

      setIsCartLoaded(true);

      // this removes the query strings form the URL
      if ([...searchParams].length > 0) {
        setSearchParams({}, { replace: true });
      }
    },
    [bulk, bulkCartId, cartDispatch, searchParams, setSearchParams]
  );

  const getCart = useCallback(
    (myCartId) => {
      API.getCart(myCartId)
        .then((res) => {
          const cartData = res.data;
          sessionStorage.removeItem('viewCheckout');
          storeCartData(cartData);
        })
        .catch((err) => {
          if (err?.name === 'CanceledError') {
            console.error('Request Aborted');
          } else if (err?.name === 'AxiosError') {
            // console.error(err);
            console.error(err?.response?.data);
          } else {
            console.error(err);
          }

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    },
    [cartDispatch, storeCartData]
  );

  const handleBackBtn = useCallback(
    (myCartId) => {
      // console.log('SET Pageshow event listener');

      if (myCartId && sessionStorage.getItem('viewCheckout') === 'true') {
        // console.log('IF Pageshow Cart useEffect');

        sessionStorage.removeItem('viewCheckout');
        // window.location.reload();

        cartDispatch({
          type: 'UPDATE',
          payload: {
            isLoading: true,
            isPageShowing: true,
          },
        });

        getCart(myCartId);
      }
    },
    [cartDispatch, getCart]
  );

  // get the cart using the query or cookie cartId
  useEffect(() => {
    // console.log('Normal Cart useEffect');

    // removing eventListener before adding one to prevent duplicates
    window.removeEventListener('pageshow', () => handleBackBtn(myCartId));
    window.addEventListener('pageshow', () => handleBackBtn(myCartId));

    // if only cartId is available, fetch the cart
    if (
      myCartId &&
      !sessionStorage.getItem('viewCheckout') &&
      !(productId || tp || groupId || coupon || host) &&
      !isCartLoaded
    ) {
      // console.log('IF Normal Cart useEffect');

      getCart(myCartId);
    }
  }, [coupon, getCart, groupId, handleBackBtn, host, isCartLoaded, myCartId, productId, tp]);

  // create or update an existing cart based on URL query string
  useEffect(() => {
    // if there is no cartId but there is is a productId query string, create a new cart
    if (!myCartId && productId) {
      const productIds = productId ? productId.toLowerCase().split(',') : [];
      const cartData = {
        cartId: null, // set to null because we are creating a new cart
        productIds: productIds,
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.createCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }

    // if there is an exising cartId and there's a productId or price modifiers, update the cart
    if (myCartId && (productId || tp || groupId || coupon || host)) {
      const productIds = productId ? productId.toLowerCase().split(',') : [];
      const cartData = {
        cartId: myCartId,
        productIds: productIds,
        pricingVariant: tp || null,
        groupId: groupId || null,
        coupon: coupon || null,
        sourceHost: host || domain,
      };
      API.updateCart(cartData)
        .then(async (res) => {
          const cartData = res.data;
          storeCartData(cartData);
        })
        .catch((err) => {
          console.error(err?.response?.data);

          // show error message or reload the page
          cartDispatch({
            type: 'ERROR',
            payload: {
              isLoading: false,
              isPageShowing: false,
              isError: true,
              error: err?.response?.data || 'General Error',
            },
          });

          if (err?.response?.data?.status >= 400) {
            console.error('Your cart is empty!');
            setIsCartEmpty(true);
          }
        });
    }
  }, [cartDispatch, coupon, domain, groupId, host, myCartId, productId, storeCartData, tp]);

  const cartItemsWithoutReplaceUpsell =
    cartState.cartProducts?.filter(
      (product) =>
        product.upSellProducts.length === 0 ||
        !product.upSellProducts.some((upsell) => {
          return upsell.upsellType === 'Replace';
        })
    ) || [];
  const cartItemsWithReplaceUpsell =
    cartState.cartProducts?.filter((product) => product.upSellProducts.some((upsell) => upsell.upsellType === 'Replace')) ||
    [];

  const sortedItems = cartState.cartProducts ? [...cartItemsWithoutReplaceUpsell, ...cartItemsWithReplaceUpsell] : undefined;

  const cartItems = sortedItems?.map((product) => {
    return <CartItem item={product} isSingle={isSingle} key={product.cart_ProductId} />;
  });

  // if cart is not empty render the cart details
  if (cartItems && cartItems.length > 0) {
    return (
      <>
        <Helmet>
          <title>Cart Details</title>
        </Helmet>

        <div className={`container-fluid but-fixed v2 ${!cartItems ? 'cart-skeleton' : ''}`}>
          <div className="row my-4">
            <div className="col-12 col-lg-8 pe-xl-4 order-1 order-lg-0 mt-lg-3">
              {isSingle && cartState.cartProducts[0]?.upSellProducts.length !== 0 ? (
                <>
                  <h1
                    className={`fs-4 fw-500 mt-2 mt-lg-0 ${
                      cartState.cartProducts[0].upsellType === 'Replace' ? 'mb-3' : 'mb-1'
                    }`}
                  >
                    {cartState.cartProducts[0].name}
                  </h1>
                  {cartState.cartProducts[0].upsellType !== 'Replace' && (
                    <div className="mb-3">
                      <button
                        className={`btn-toggle description-toggle text-start align-items-start small fw-600 mb-1 ${
                          isDescriptionCollapsed ? '' : 'is-expanded'
                        }`}
                        onClick={() => collapse.toggle()}
                      >
                        <span className="align-middle">View Description</span>
                      </button>

                      <div
                        className={`upsells-wrapper collapse`}
                        // className={`upsells-wrapper mt-3 ${!isSingle && 'collapse'}`}
                        // className={`upsells-wrapper mt-3`}
                        ref={descriptionCollapse}
                        id={`upsells-collapse-${cartState.cartProducts[0].cart_ProductId}`}
                      >
                        <p className="mb-0">{cartState.cartProducts[0].description}</p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <h1 className="fs-4 fw-400 mt-2 mt-lg-0 mb-2">Order Details</h1>
              )}

              {cartItems}

              <AttestationForm />

              <div className="row justify-content-end my-5">
                <div className="col-md-6">
                  <CheckoutBtn />
                </div>
              </div>

              <RelatedUpsells />
            </div>
            <aside className="col-sm-12 col-md-12 col-lg-4 order-0 order-lg-1 mt-lg-3">
              <OrderSummary />
            </aside>
            {/* <div className="col-12 col-lg-8 pe-lg-4 order-2 order-lg-2">
              <div className="row justify-content-end my-4">
                <div className="col-md-6">
                  <CheckoutBtn />
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <ValidationModal />

        <FeeDescriptionModal />

        <ToastNotification />
      </>
    );
  }

  // if the cart is empty show empty cart message
  if (isCartEmpty || (!myCartId && !productId) || (cartItems && cartItems.length === 0)) {
    return <CartEmpty />;
  }

  // preloader skeleton screen
  return <CartSkeleton />;
};

export default Cart;

export const ErrorBoundary = () => {
  return <Error fromPage="Cart Page" />;
};
