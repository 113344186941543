import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import BrandProvider from './store/BrandProvider';
import Layout from './components/Layout';
import Page404 from './pages/404';

import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/open-sans/400-italic.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/500-italic.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/600-italic.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/700-italic.css';
import '@fontsource/roboto-slab'; // Defaults to weight 400

// import 'bootstrap/dist/js/bootstrap.min.js';
import './scss/bootstrap.scss';
import './scss/app.scss';

// ESlint complains about children prop-types, this is a fix for that
import PropTypes from 'prop-types';
ScrollToTop.propTypes = {
  children: PropTypes.node,
};

// component that handles scroll to top page transistion
function ScrollToTop(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
}

const pages = import.meta.glob('./pages/**/*.jsx', { eager: true });
const routes = [];

for (const path of Object.keys(pages)) {
  const fileName = path.match(/\.\/pages\/(.*)\.jsx$/)?.[1];
  if (!fileName) {
    continue;
  }

  const normalizedPathName = fileName.includes('$') ? fileName.replace('$', ':') : fileName.replace(/\/index/, '');

  routes.push({
    path: fileName === 'index' ? '/' : `/${normalizedPathName.toLowerCase()}`,
    Element: pages[path].default,
    // loader: pages[path]?.loader | undefined,
    // action: pages[path]?.action | undefined,
    ErrorBoundary: pages[path]?.ErrorBoundary,
  });
}

const router = createBrowserRouter([
  {
    // parent route component
    element: (
      <ScrollToTop>
        <Layout />
      </ScrollToTop>
    ),
    // 404 page
    errorElement: (
      <Layout>
        <Page404 />
      </Layout>
    ),
    // child route components
    children: [
      ...routes.map(({ Element, ErrorBoundary, ...rest }) => ({
        ...rest,
        element: <Element />,
        ...(ErrorBoundary && { errorElement: <ErrorBoundary /> }),
      })),
    ],
  },
]);

const App = () => {
  return (
    <BrandProvider>
      <RouterProvider router={router} />
    </BrandProvider>
  );
};

export default App;
