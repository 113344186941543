// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'flDrivingRecord', // this used as the field name as well as the unique identifier
    productIds: [
      '3efbb680-1a4b-44ff-82d1-258797967ee8', // Florida 3 Year Driving Record
      'c2aaad79-7da9-4cf3-9fde-c1d7eaadcd32', // Florida 7 Year Driving Record
      'f0a0a6ce-1647-4398-6493-08dc1ded4cd5', // Florida Complete Driving Record
      'bf7be5d5-066d-46d9-f20a-08db86eadae8', // for QA using the General Use QA Product, only in ML
    ],
    errorMsg: `You must agree with the <strong>Florida Driving Records</strong> legitimate business purpose by typing "<strong>I agree</strong>" in the highlighted box.`,
    onlyForBulk: true, // set to true if this attestation is only for bulk purchase
    type: 'iAgree', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb({ handleModal }) {
  return (
    <>
      To purchase multiple <strong>Florida Driving Records</strong>, you must agree they will be used for a{' '}
      <a href="#" onClick={handleModal}>
        legitimate business purpose
      </a>{' '}
      with employee approval by typing &quot;<strong>I agree</strong>&quot; in the box below.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return (
    <>
      <h3 className="modal-title fw-600 mb-3">Driving Record Legitimate Business Purpose</h3>
      <p>
        To request the personal driving records of other individuals, your business must have a legitimate reason for the
        request. The legal reasons are listed in the{' '}
        <a href="https://www.law.cornell.edu/uscode/text/18/2721" target="_blank" rel="noopener noreferrer nofollow">
          Driver&apos;s Privacy Protection Act
        </a>
        . According to Florida state law, driving records can be released only for the purposes outlined below.
      </p>
      <p>
        Personal information shall be disclosed for use in connection with matters of motor vehicle or driver safety and
        theft, motor vehicle emissions, motor vehicle product alterations, recalls, or advisories, performance monitoring of
        motor vehicles and dealers by motor vehicle manufacturers, and removal of non-owner records from the original owner
        records of motor vehicle manufacturers to carry out the purposes of titles I and IV of the Anti-Car Theft Act of
        1992, the Automobile Information Disclosure Act, the Clean Air Act, and chapters 301, 305, and 321-331 of title 49,
        may be disclosed as follows.
      </p>
      <ol>
        <li className="mb-3">
          For use by any government agency, including any court or law enforcement agency, in carrying out its function, or
          any private person or entity acting on behalf of a Federal, State, or local agency in carrying out its functions.
        </li>
        <li className="mb-3">
          For use in connection with matters of motor vehicle or driver safety and theft; motor vehicle emissions; motor
          vehicle product alterations, recalls, or advisories; performance monitoring of motor vehicles, motor vehicle parts
          and dealers; motor vehicle market research activities, including survey research; and removal of non-owner records
          from the original owner records of motor vehicle manufacturers.
        </li>
        <li className="mb-3">
          For use in the normal course of business by a legitimate business or its agents, employees, or contractors, but
          only (a) to verify the accuracy of personal information submitted by the individual to the business or its agents,
          employees, or contractors; and (b) if such information as so submitted is not correct or is no longer correct, to
          obtain the correct information, but only for the purposes of preventing fraud by, pursuing legal remedies against,
          or recovering on a debt or security interest against, the individual.
        </li>
        <li className="mb-3">
          For use in connection with any civil, criminal, administrative, or arbitral proceeding in any Federal, State, or
          local court or agency or before any self-regulatory body, including the services or process, investigation in
          anticipation of litigation, and the execution or enforcement of judgments and orders, or pursuant to an order of a
          Federal State, or local court.
        </li>
        <li className="mb-3">
          For use in research activities, and for use in producing statistical reports, so long as the personal information
          is not published, redisclosed, or used to contact individuals.
        </li>
        <li className="mb-3">
          For use by any insurer or insurance support organization, or by a self-insured entity, or its agents, employees, or
          contractors, in connection with claims investigation activities, antifraud activities, rating or underwriting.
        </li>
        <li className="mb-3">For use in providing notice to the owners of towed or impounded vehicles.</li>
        <li className="mb-3">
          For use by any licensed private investigative agency or licensed security service for any purpose permitted under
          this subsection.
        </li>
        <li className="mb-3">
          For use by an employer or its agent or insurer to obtain or verify information relating to a holder of a commercial
          driver&apos;s license that is required under chapter 313 of title 49.
        </li>
        <li className="mb-3">For use in connection with the operations of private toll transportation facilities.</li>
        <li className="mb-3">
          For any other use in response to request for individual motor vehicle records if the State has obtained the express
          consent of the person to whom such personal information pertains.
        </li>
        <li className="mb-3">
          For bulk distribution for surveys, marketing, or solicitations if the State has obtained the express consent of the
          person to whom such personal information pertains.
        </li>
        <li className="mb-3">
          For use by any requestor, if the requester demonstrates it has obtained the written consent of the individual to
          whom the information pertains.
        </li>
        <li className="mb-3">
          For any other use specifically authorized under the law of the State that holds the record if such use is related
          to the operation of a motor vehicle or public safety
        </li>
      </ol>
    </>
  );
}
