export const ecomAddProduct = (item) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [
          {
            item_id: item.productId,
            item_name: item.name,
            item_variant: item.pricingUsed,
            price: item.unitPriceWithDiscountAndFeeIfHidden,
            quantity: item.quantity,
            discount: item.unitDiscountAmount,
            sourceProductId: item.sourceProductId,
            upsellType: item.upsellType,
          },
        ],
      },
    },
  });
};

export const ecomRemoveProduct = (item) => {
  const selectedUpsells = item?.upSellProducts
    .filter((upsell) => upsell.isSelected)
    .map((item) => {
      return {
        item_id: item.productId,
        item_name: item.name,
        item_variant: item.pricingUsed,
        price: item.unitPriceWithDiscountAndFeeIfHidden,
        quantity: item.quantity,
        discount: item.unitDiscountAmount,
        sourceProductId: item.sourceProductId,
        upsellType: item.upsellType,
      };
    });
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            item_id: item.productId,
            item_name: item.name,
            item_variant: item.pricingUsed,
            price: item.unitPriceWithDiscountAndFeeIfHidden,
            quantity: item.quantity,
            discount: item.unitDiscountAmount,
            sourceProductId: item.sourceProductId,
            upsellType: item.upsellType,
          },
          ...selectedUpsells,
        ],
      },
    },
  });
};
