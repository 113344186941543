export const getDisplayPrice = (item, isUpsell = false) => {
  let amount;
  // if there is a fee
  if (item.unitThirdPartyFee > 0) {
    if (item.hiddenFee) {
      if (item.unitDiscountAmount > 0) {
        amount = item.unitPriceWithDiscountAndFeeIfHidden;
      } else {
        amount = item.unitPriceWithUnitFeeIfHidden;
      }
    } else {
      if (item.unitDiscountAmount > 0) {
        if (isUpsell) {
          amount = item.unitPriceAndFee - item.unitDiscountAmount;
        } else {
          amount = item.unitPrice - item.unitDiscountAmount;
        }
      } else {
        if (isUpsell) {
          amount = item.unitPriceAndFee;
        } else {
          amount = item.unitPrice;
        }
      }
    }
  }
  // if there's no fees
  else {
    if (item.unitDiscountAmount > 0) {
      amount = item.unitPrice - item.unitDiscountAmount;
    } else {
      amount = item.unitPrice;
    }
  }
  return amount;
};

export const getOrigPrice = (item, isUpsell = false) => {
  let amount;
  // if there is a fee
  if (item.unitThirdPartyFee > 0) {
    if (item.hiddenFee) {
      amount = item.unitPriceAndFee;
    } else {
      if (isUpsell) {
        amount = item.unitPriceAndFee;
      } else {
        amount = item.unitPrice;
      }
    }
  }
  // if there's no fees
  else {
    amount = item.unitPrice;
  }
  return amount;
};

export const getUpsellTotal = (upsells) => {
  // we are excluding any selected replace upsell from the calculations
  // when an upsellType=Replace upsell is marked as selected it means that the main product is itself
  // this also means that the replace upsell price is alreday included in the main item's price
  return upsells
    .filter((upsell) => upsell.isSelected && upsell.upsellType !== 'Replace')
    .reduce((acc, cur) => {
      return acc + cur.unitPriceWithDiscountAndFee * cur.quantity;
    }, 0);
};
