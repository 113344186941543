// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'designTestCheckbox', // this used as the field name as well as the unique identifier
    productIds: [
      '98caee42-8d39-41b2-0e14-08dbe6b872bb', // Design Test Product - HiddenFee=false
    ],
    errorMsg: `You must attest that you understand the <strong>Design Checkbox Product</strong> terms by checking the highlighted checkbox.`,
    onlyForBulk: true, // set to true if this attestation is only for bulk purchase
    type: 'checkbox', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb({ handleModal }) {
  return (
    <>
      By checking this box, I agree with the <strong>Design Checkbox Product</strong>{' '}
      <a href="#" onClick={handleModal}>
        terms and conditions
      </a>{' '}
      and that I have read, understand.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return (
    <>
      <h3 className="modal-title fw-600 mb-3">Design Checkbox Product Terms</h3>
      <p>All students need to agree to the following statements in order to enroll in the course:</p>
      <ol>
        <li className="mb-3">The student who registers for this course is the one that takes the course.</li>
        <li className="mb-3">
          The course provider will determine how many times a student can attempt to answer questions correctly.
        </li>
        <li className="mb-3">And so on, this is just an example...</li>
      </ol>
    </>
  );
}
