// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'txParentTaughtDriverEducationAMIASC', // this used as the field name as well as the unique identifier
    productIds: [
      '70efb450-8c97-4b5a-b82e-736b45ccc3b8', // Texas Driver Education - Alternative Method of Instruction + Texas Department of Public Safety Online Highway Sign and Traffic Law Exam
      '06881a75-c9fb-498a-92ff-08dce3af17cb', // Texas Driver Education - Alternative Method of Instruction
    ],
    errorMsg: `The Student must accept the <strong>Texas Driver Education enrollment contract</strong> by typing their <strong>full legal name</strong> in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'multiSignature', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      The Student accepts the{' '}
      <a
        href="https://cdn.certus.com/global/pdf/tx-de-ami-enrollment-contract-asc.pdf"
        target="_blank"
        rel="noopener nofollow noreferrer"
      >
        Texas Driver Education enrollment contract
      </a>{' '}
      by typing their full legal name in the field below. If the Student is under 18 years of age, the parent or legal
      guardian of the Student must also accept this contract by typing their full legal name in the field below.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
