const cookies = {
  get: function (name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },

  set: function (name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    const location = window.location;
    const domain =
      location.hostname === 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname === 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },

  delete: function (name) {
    const location = window.location;
    const domain =
      location.hostname === 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname === 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' + domain + security;
  },
};

export default cookies;
