import throttle from 'lodash.throttle';

export default function ExitIntent(options = {}) {
  const defaultOptions = {
    threshold: 20,
    maxDisplays: 1,
    eventThrottle: 200,
    delay: 0,
    onExitIntent: () => {},
  };

  return (function () {
    const config = { ...defaultOptions, ...options };
    const eventListeners = new Map();
    let displays = 0;

    // tracking if mouse entered the screen
    // will be set to false if the mouse have passed the threshold config
    let mouseJustEntered = true;

    const addEvent = (eventName, callback) => {
      document.addEventListener(eventName, callback, false);
      eventListeners.set(`document:${eventName}`, { eventName, callback });
    };

    const removeEvent = (key) => {
      const { eventName, callback } = eventListeners.get(key);
      document.removeEventListener(eventName, callback);
      eventListeners.delete(key);
    };

    const shouldDisplay = (positionY) => {
      if (positionY <= config.threshold && displays < config.maxDisplays && !mouseJustEntered) {
        displays++;
        return true;
      }

      return false;
    };

    const mouseDidMove = (event) => {
      if (shouldDisplay(event.clientY)) {
        config.onExitIntent();
        if (displays >= config.maxDisplays) {
          removeEvents();
        }
      }
      if (event.clientY >= config.threshold) {
        mouseJustEntered = false;
      }
      if (
        event.clientX <= config.threshold ||
        event.clientX >= window.innerWidth - config.threshold ||
        event.clientY >= window.innerHeight - config.threshold
      ) {
        mouseJustEntered = true;
      }
    };

    const removeEvents = () => {
      eventListeners.forEach((value, key) => removeEvent(key));
    };

    setTimeout(() => {
      addEvent('mousemove', throttle(mouseDidMove, config.eventThrottle));
    }, config.delay);

    return removeEvents;
  })();
}
