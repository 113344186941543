import { Helmet } from 'react-helmet-async';
import useBrand from '../hooks/useBrand';
import graphic404 from '../assets/404-graphic.svg';
import Error from '../components/ErrorBoundary';

const NotFound = () => {
  const { domain } = useBrand();

  return (
    <>
      <Helmet>
        <title>Not Found</title>
        {/* <link rel="canonical" href={`https://www.${domain}`} /> */}
      </Helmet>

      <div className="container-fluid but-fixed py-5">
        <div className="row justify-content-center">
          <article className="col-12 col-lg-9 text-center my-3">
            <img className="graphic-404 img-fluid mb-4" src={graphic404} alt="404" />
            <h1>Oopsies, something went wrong...</h1>
            <p>We&#39;re sorry, the page you requested cannot be found or your session has expired.</p>
            <p className="mt-4 mb-0">
              <a className="btn btn-outline-secondary" href={`https://${domain}/`}>
                Continue Shopping
              </a>
            </p>
          </article>
        </div>
      </div>
    </>
  );
};

export default NotFound;

export const ErrorBoundary = () => {
  return <Error fromPage="404 Page" />;
};
