import { Helmet } from 'react-helmet-async';

export default function CartSkeleton() {
  return (
    <>
      <Helmet>
        <title>Cart Details</title>
      </Helmet>

      {/* <div className="container-fluid but-fixed cart-skeleton"> */}
      <div className="container-fluid but-fixed cart-skeleton">
        <div className="row justify-content-center py-3 py-md-4">
          <div className="col-12 col-lg-8 pe-xl-4 order-1 order-lg-0 mt-lg-3">
            <h1 className="fs-4 fw-300 mb-2 mb-lg-3">
              <span className="animated-placeholder w-40 height-lg"></span>
            </h1>

            <div className="rounded bg-light p-3 mb-2">
              <div className="w-100">
                <div className="d-flex justify-content-center mb-1">
                  <div className="w-100">
                    <span className="animated-placeholder w-50 w-md-60 height-md"></span>
                  </div>
                  <div className="w-100" style={{ maxWidth: '70px' }}>
                    <span className="animated-placeholder w-100"></span>
                  </div>
                </div>
                <span className="d-block lh-15">
                  <span className="animated-placeholder w-100 height-sm"></span>
                  <span className="animated-placeholder w-90 height-sm"></span>
                  <span className="animated-placeholder w-50 height-sm"></span>
                </span>
              </div>
            </div>
            <div className="rounded bg-light p-3 mb-2">
              <div className="w-100">
                <div className="d-flex justify-content-center mb-1">
                  <div className="w-100">
                    <span className="animated-placeholder w-50 w-md-60 height-md"></span>
                  </div>
                  <div className="w-100" style={{ maxWidth: '70px' }}>
                    <span className="animated-placeholder w-100"></span>
                  </div>
                </div>
                <span className="d-block lh-15">
                  <span className="animated-placeholder w-100 height-sm"></span>
                  <span className="animated-placeholder w-90 height-sm"></span>
                  <span className="animated-placeholder w-50 height-sm"></span>
                </span>
              </div>
            </div>

            {/* <div className="cart-items mb-4">
              <div className="cart-item py-3">
                <div className="d-flex mb-2">
                  <h3 className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-100 height-lg"></span>
                  </h3>
                  <div className="price-display text-end w-40 w-md-50">
                    <span className="animated-placeholder w-60 w-md-40 height-lg"></span>
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <div className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-40 height-lg me-3"></span>
                    <span className="animated-placeholder w-20 height-sm"></span>
                  </div>
                  <div className="price-display text-end w-50">
                    <span className="animated-placeholder w-50 w-md-30 height-sm"></span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-30 height-sm"></span>
                  </div>
                </div>
              </div>
              <div className="cart-item py-3">
                <div className="d-flex mb-2">
                  <h3 className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-100 height-lg"></span>
                  </h3>
                  <div className="price-display text-end w-40 w-md-50">
                    <span className="animated-placeholder w-60 w-md-40 height-lg"></span>
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <div className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-40 height-lg me-3"></span>
                    <span className="animated-placeholder w-20 height-sm"></span>
                  </div>
                  <div className="price-display text-end w-50">
                    <span className="animated-placeholder w-50 w-md-30 height-sm"></span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="h4 w-100 mb-0">
                    <span className="animated-placeholder w-30 height-sm"></span>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-12 text-end mt-4 mb-4">
              <a href="/checkout/" className="checkout-btn btn btn-lg btn-success w-100 w-md-50 mt-2 disabled" disabled>
                Checkout Now
              </a>
            </div>
          </div>

          <aside className="col-sm-12 col-md-12 col-lg-4 order-0 order-lg-1 mt-lg-3">
            <div className="card card-summary sticky-top py-1 mb-3">
              <div className="card-body py-0 px-3">
                <ul className="list-group list-group-flush bg-transparent m-0">
                  <li className="list-group-item px-0">
                    <div className="item-details">
                      <h3 className="fs-6 fw-600 my-1">
                        <span className="animated-placeholder w-50 height-md"></span>
                      </h3>
                    </div>
                    <div className="item-amount text-end d-none w-100">
                      <span className="animated-placeholder w-50"></span>
                    </div>
                  </li>

                  {/* <li className="list-group-item d-block px-0">
                    <div className="cart-item d-flex fw-600">
                      <div className="item-details">New York DMV Approved Defensive Driving Course Online (IPIRP)</div>
                      <div className="item-amount text-end">$24.95</div>
                    </div>
                    <ul className="cart-item-upsell list-group-flush bg-transparent ps-2 m-0 mt-1">
                      <li className="list-group-item border-0 p-0">
                        <div className="item-details flex-grow-1">New York Defensive Driving Study Guide</div>
                        <div className="item-amount text-end">$6.95</div>
                      </li>
                      <li className="list-group-item p-0">
                        <div className="item-details flex-grow-1">Premier Pass</div>
                        <div className="item-amount text-end">$2.95</div>
                      </li>
                    </ul>
                  </li> */}

                  <li className="list-group-item d-block px-0">
                    <div className="cart-item-qty d-flex justify-content-between">
                      <span className="animated-placeholder w-20"></span>
                    </div>
                    <div className="cart-item d-flex fw-600">
                      <div className="item-details">
                        <span className="animated-placeholder w-80"></span>
                      </div>
                      <div className="item-amount text-end">
                        <span className="animated-placeholder w-100"></span>
                      </div>
                    </div>
                    <ul className="cart-item-upsell list-group-flush bg-transparent ps-2 m-0">
                      <li className="list-group-item border-0 p-0">
                        <div className="item-details flex-grow-1">
                          <span className="animated-placeholder w-60"></span>
                        </div>
                        <div className="item-amount text-end">
                          <span className="animated-placeholder w-80"></span>
                        </div>
                      </li>
                      <li className="list-group-item p-0">
                        <div className="item-details flex-grow-1">
                          <span className="animated-placeholder w-70"></span>
                        </div>
                        <div className="item-amount text-end">
                          <span className="animated-placeholder w-80"></span>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="list-group-item d-block px-0">
                    <div className="cart-item-qty d-flex">
                      <span className="animated-placeholder w-20"></span>
                    </div>
                    <div className="cart-item d-flex fw-600">
                      <div className="item-details">
                        <span className="animated-placeholder w-80"></span>
                      </div>
                      <div className="item-amount text-end">
                        <span className="animated-placeholder w-100"></span>
                      </div>
                    </div>
                    <ul className="cart-item-upsell list-group-flush bg-transparent ps-2 m-0">
                      <li className="list-group-item border-0 p-0">
                        <div className="item-details flex-grow-1">
                          <span className="animated-placeholder w-60"></span>
                        </div>
                        <div className="item-amount text-end">
                          <span className="animated-placeholder w-80"></span>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className="list-group-item total fw-600 fs-6 px-0">
                    <div className="item-details w-100">
                      <span className="animated-placeholder w-50"></span>
                    </div>
                    <div className="item-amount text-end w-100">
                      <span className="animated-placeholder"></span>
                    </div>
                  </li>

                  {/* <li className="list-group-item savings px-0">
                    <div className="item-details w-100">
                      <span className="animated-placeholder w-70"></span>
                    </div>
                    <div className="item-amount text-end w-100">
                      <span className="animated-placeholder w-50"></span>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <div id="promo-code-wrap" className="form-inline d-flex my-3">
              <input id="promo-code-input" className="form-control" type="text" placeholder="Promo Code" disabled />
              <button id="promo-code-btn" className="btn btn-outline-secondary" type="button" disabled>
                Apply
              </button>
            </div> */}
            {/* <a href="/checkout/" className="checkout-btn btn btn-lg btn-success w-100 mt-2 disabled" disabled>
              Checkout Now
            </a> */}
          </aside>
        </div>
      </div>
    </>
  );
}
