import { useReducer } from 'react';
import { CartContext } from './CartContext';
import { cartReducer, initialCart } from './CartReducer';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CartProvider.propTypes = {
  children: PropTypes.node,
};

export default function CartProvider({ children }) {
  const [cart, dispatch] = useReducer(cartReducer, initialCart);

  return <CartContext.Provider value={[cart, dispatch]}>{children}</CartContext.Provider>;
}
