import { useEffect, useContext, useRef } from 'react';
import { CartContext } from '../store/CartContext';
import { Modal } from 'bootstrap';

export default function ErrorModal() {
  const [cartState] = useContext(CartContext);

  const errorModalEl = useRef();

  useEffect(() => {
    if (cartState.isError) {
      const modal = new Modal(errorModalEl.current, {
        backdrop: 'static',
      });
      modal.show();
    }
  }, [cartState.isError]);

  return (
    <>
      <div className="modal modal-lg fade text-start" ref={errorModalEl} id="error-modal" tabIndex="-1" aria-hidden="true">
        <div className="bulk-modal modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-3 p-md-4">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <h3 className="modal-title fw-600 h3 mb-3">Oops, something went wrong...</h3>
              <p className="fw-500 mb-0">
                Looks like we{"'"}re experiencing some technical difficulties. Please{' '}
                <a href="#" onClick={() => window.location.reload()}>
                  refresh the page
                </a>{' '}
                and try again.
              </p>
              {/* <p className="line-break mb-0">OperationId: {cartState?.error?.operationId}</p>
              <p className="line-break mb-0">Message: {cartState?.error?.message}</p> */}
            </div>{' '}
            <div className="modal-footer p-3 p-md-4 pt-0 pt-md-0 border-0">
              <button
                type="button"
                className="btn btn-primary px-5 fw-700"
                data-bs-dismiss="modal"
                onClick={() => window.location.reload()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
