// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'inTeenDriving', // this used as the field name as well as the unique identifier
    productIds: [
      '47a20fda-fceb-44cc-8d40-1b7e170f85cc', // Indiana Teen Driving
    ],
    errorMsg: `You must acknowledge that students seeking Indiana driving privileges must be enrolled at a driver training school licensed by the Indiana Bureau of Motor Vehicles and must be at least 15 years of age by checking the highlighted checkbox.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'checkbox', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb() {
  return (
    <>
      I acknowledge that students seeking Indiana driving privileges must be enrolled at a driver training school licensed by
      the Indiana Bureau of Motor Vehicles and must be at least 15 years of age to earn credit for completing online driver
      training.
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return null;
}
