import parse from 'html-react-parser';
import { formatCurrency as currency } from '../../utils/formatCurrency';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
BulkRatesModal.propTypes = {
  productId: PropTypes.number.isRequired,
  productName: PropTypes.string.isRequired,
  cartProductBulkTiers: PropTypes.array.isRequired,
};

export default function BulkRatesModal({ productId, productName, cartProductBulkTiers }) {
  const bulkRateInfo = () => {
    // WORKAROUND: deduping the bulk tiers array because the cart API is returning duplicate bulk tiers
    // const cleanBulkTiers = cartProductBulkTiers.filter(
    //   (tier, index, arr) => arr.findIndex((tier2) => tier2.minQuantity === tier.minQuantity) === index
    // );

    const bulkTiers = cartProductBulkTiers.map((tier, index) => {
      return {
        ...tier,
        maxQuantity: cartProductBulkTiers[index + 1] ? cartProductBulkTiers[index + 1].minQuantity - 1 : null,
      };
    });

    let bulkRates = '';

    for (let i = 0; i < bulkTiers.length; i++) {
      const bulkPrice = currency(bulkTiers[i].discountTierUnitPrice);
      const minQuantity = bulkTiers[i].minQuantity;
      const maxQuantity = bulkTiers[i].maxQuantity;

      bulkRates += `Buy <span class="fw-700">${minQuantity}${
        maxQuantity ? `-${maxQuantity}` : ' or more'
      }</span> and get a unit price of <span class="fw-700">${bulkPrice}</span>${maxQuantity ? '<br/>' : ''}`;
    }

    return bulkRates;
  };

  return (
    <div className="modal fade text-start lh-16" id={`upsells-bulk-rate-${productId}`} tabIndex="-1" aria-hidden="true">
      <div className="bulk-modal modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body p-3 p-md-4">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3 className="modal-title fw-600 h5 mb-3">Bulk Rates for {productName}</h3>
            <p className="line-break fw-500 mb-0">{parse(bulkRateInfo())}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
