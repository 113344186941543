import useBrand from '../hooks/useBrand';
import parse from 'html-react-parser';

export default function Footer() {
  const { footer, useFallback } = useBrand();

  if (footer) {
    return parse(footer);
  }

  // fallback default footer
  if (useFallback) {
    return (
      <footer id="footer-wrap" className="footer bg-light border-top mt-auto pt-3">
        <div className="container-fluid but-fixed">
          <div className="row">
            <div id="footer" className="col-sm-12 text-center">
              <p>Copyright &#169; Certus. All Rights Reserved.</p>
              <p className="mt-3">
                <a
                  className="border-0"
                  href="https://sealserver.trustwave.com/cert.php?customerId=467c44ce96744facaf7102074ba1e116&size=105x54&style=invert"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://cdn.amersc.com/global/images/trustwave-seal.png"
                    alt="Trustwave Validated"
                    width="105"
                    height="54"
                    loading="lazy"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  // skeleton footer
  return (
    <footer id="footer-wrap" className="footer bg-light border-top mt-auto pt-3">
      <div className="container-fluid but-fixed">
        <div className="row">
          <div id="footer" className="col-sm-12 text-center">
            <p>
              <span className="animated-placeholder w-30" style={{ minWidth: '250px' }}></span>
            </p>
            <p className="mt-3">
              <span className="animated-placeholder rounded-2" style={{ width: '105px', height: '54px' }}></span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
