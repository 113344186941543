import { useState, useContext, useEffect, useMemo } from 'react';
import { CartContext } from '../../store/CartContext';
import FieldGroup from './FieldGroup';
import CheckboxGroup from './CheckboxGroup';

// compiling a list of all the attestation fields in the /attestations/ folder
const fields = import.meta.glob('./attestations/**/*.jsx', { eager: true });
const attestationItems = [];

for (const path of Object.keys(fields)) {
  const fileName = path.match(/\.\/attestations\/(.*)\.jsx$/)?.[1];
  if (!fileName) {
    continue;
  }

  attestationItems.push({
    name: fields[path].Config().name,
    productIds: fields[path].Config().productIds,
    errorMsg: fields[path].Config().errorMsg,
    onlyForBulk: fields[path].Config().onlyForBulk,
    type: fields[path].Config().type,
    Blurb: fields[path].Blurb,
    ModalContent: fields[path].ModalContent,
  });
}

const iAgreeItems = attestationItems.filter((field) => field.type === 'iAgree');
const signatureItems = attestationItems.filter((field) => field.type === 'signature');
const multiSignatureItems = attestationItems.filter((field) => field.type === 'multiSignature');
const checkboxItems = attestationItems.filter((field) => field.type === 'checkbox');

export default function AttestationForm() {
  const [cartState, cartDispatch] = useContext(CartContext);

  const cartProductIds = useMemo(() => cartState.cartProducts.map((item) => item.productId), [cartState.cartProducts]);

  const [attestForm, setAttestForm] = useState({
    fields: {
      // [fieldName]: {
      //   value: "",
      //   isValid: false,
      // },
    },
    errors: {
      // [fieldName]: "Error message goes here...",
    },
    touched: {
      // [fieldName]: false,
    },
  });

  useEffect(() => {
    const isValid = Object.values(attestForm.errors).length === 0;

    sessionStorage.setItem(
      'attestForm',
      JSON.stringify({
        fields: attestForm.fields,
      })
    );

    // console.log('attestForm', attestForm);

    // store cart validation state in CartContext
    cartDispatch({
      type: 'VALIDATION',
      payload: {
        isValid: isValid,
        errors: isValid ? {} : attestForm.errors,
      },
    });
  }, [attestForm, cartDispatch]);

  if (attestationItems.length) {
    return (
      <>
        <FieldGroup
          fieldType="iAgree"
          cartProductIds={cartProductIds}
          attestItems={iAgreeItems}
          attestForm={attestForm}
          setAttestForm={setAttestForm}
        />
        <FieldGroup
          fieldType="signature"
          cartProductIds={cartProductIds}
          attestItems={signatureItems}
          attestForm={attestForm}
          setAttestForm={setAttestForm}
        />
        <FieldGroup
          fieldType="multiSignature"
          cartProductIds={cartProductIds}
          attestItems={multiSignatureItems}
          attestForm={attestForm}
          setAttestForm={setAttestForm}
        />
        <CheckboxGroup
          fieldType="checkbox"
          cartProductIds={cartProductIds}
          attestItems={checkboxItems}
          attestForm={attestForm}
          setAttestForm={setAttestForm}
        />
      </>
    );
  }

  return null;
}
