import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CheckboxItem.propTypes = {
  cartState: PropTypes.object.isRequired,
  attestForm: PropTypes.object.isRequired,
  attestItem: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
};

export default function CheckboxItem({ cartState, attestForm, attestItem, handleModalClose, handleChange, fieldType }) {
  const attestModalEl = useRef();
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (attestItem.ModalContent()) {
      setModal(new Modal(attestModalEl.current));
    }
  }, [attestItem]);

  const handleModal = (e) => {
    e.preventDefault();
    modal.show();
  };

  const handleCloseCheckboxModal = (e) => {
    e.preventDefault();
    modal.hide();
    // attestModalEl.current.addEventListener('hidden.bs.modal', () => {
    handleModalClose(attestItem.name);
    // });
  };

  return (
    <>
      <div className="form-check">
        <input
          className={`form-check-input ${
            (attestForm.touched[attestItem.name] || cartState.validation.formTouched) &&
            !attestForm.fields[fieldType].isValid &&
            !attestForm.fields[attestItem.name]?.isValid
              ? 'bg-danger bg-opacity-10 border-danger'
              : ''
          }`}
          type="checkbox"
          name={attestItem.name}
          id={`${attestItem.name}-checkbox`}
          checked={attestForm.fields[attestItem.name] ? attestForm.fields[attestItem.name]?.value : false}
          onChange={handleChange}
        />
        <label
          className={`form-check-label ${
            (attestForm.touched[attestItem.name] || cartState.validation.formTouched) &&
            !attestForm.fields[fieldType].isValid &&
            !attestForm.fields[attestItem.name]?.isValid
              ? 'text-danger'
              : ''
          }`}
          htmlFor={`${attestItem.name}-checkbox`}
        >
          <attestItem.Blurb handleModal={handleModal} />
        </label>

        {attestItem.ModalContent() && (
          <div
            className="modal fade text-start"
            ref={attestModalEl}
            id={`${attestItem.name}-modal`}
            tabIndex="-1"
            aria-hidden="true"
          >
            <div className="attestation-modal modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body fw-400 p-4 p-md-4">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

                  <attestItem.ModalContent />

                  <p className="text-end mt-5 mb-0">
                    <button
                      type="button"
                      className="btn btn-primary px-5 fw-600"
                      // data-bs-dismiss="modal"
                      onClick={handleCloseCheckboxModal}
                    >
                      I agree
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
