import { useState, useEffect, useContext, useRef } from 'react';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../utils/amountHelper';
import { ecomAddProduct, ecomRemoveProduct } from '../utils/ecomHelper';
import API from '../services/apiService';
import { CartContext } from '../store/CartContext';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
UpsellShipping.propTypes = {
  item: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default function UpsellShipping({ item, prefix = '' }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const shippingUpsells = item.upSellProducts
    .filter((upsell) => upsell.upsellSectionId === 4)
    .sort((a, b) => a.upsellOrderIndex - b.upsellOrderIndex);

  const shippingUpsellsProductIds = shippingUpsells.map((item) => item.productId);

  const currentUpsell = shippingUpsells.filter((upsell) => upsell.isSelected)[0];
  // console.log(currentUpsell?.cart_ProductId);

  const [selectedUpsell, setSelectedUpsell] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentUpsellId, setCurrentUpsellId] = useState(null);

  useEffect(() => {
    setSelectedUpsell((state) => ({
      ...state,
      productId: currentUpsell?.productId || null,
      prevProductId: null,
      cartProductId: currentUpsell?.cart_ProductId || null,
      prevCartProductId: null,
      isLoading: false,
    }));
  }, [currentUpsell]);

  const storeCart = (cartData) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        ...cartData,
        isLoading: false,
      },
    });
  };

  const storeCartProducts = (updatedCartProducts) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        cartProducts: updatedCartProducts,
        isLoading: true,
      },
    });
  };

  const upsellDebounce = useRef();

  const handleChange = (event) => {
    const { value, type, checked, dataset } = event.target;
    const theValue = type === 'checkbox' && checked ? value : null;

    setIsUpdating(true);
    setCurrentUpsellId(value);

    const selectedProductId = theValue;
    const selectedCartProductId = selectedProductId && dataset.cartProductId;
    const prevProductId = selectedUpsell.productId;
    const prevCartProductId = selectedUpsell.cartProductId;

    // console.log(selectedProductId);
    // console.log(selectedCartProductId);
    // console.log(prevProductId);
    // console.log(prevCartProductId);

    setSelectedUpsell((state) => ({
      ...state,
      productId: theValue,
      cartProductId: selectedProductId && dataset.cartProductId,
      prevProductId: state.productId,
      prevCartProductId: state.prevCartProductId,
      isLoading: true,
    }));

    // if isSelected remove the other selected shipping upsell
    const updatedCartProducts = cartState?.cartProducts?.map((product) => {
      if (product.productId === item.productId) {
        const upsells = product.upSellProducts;
        const updatedUpsells = upsells.map((productUpsell) => {
          // removes previusly selected digital upsell
          if (shippingUpsellsProductIds.includes(productUpsell.productId)) {
            return {
              ...productUpsell,
              isSelected: productUpsell.productId === selectedProductId,
            };
          }
          return productUpsell;
        });

        return {
          ...product,
          upSellProducts: updatedUpsells,
        };
      }
      return product;
    });

    storeCartProducts(updatedCartProducts);

    // debouncing API call in case the user repeated clicks the upsell option
    clearTimeout(upsellDebounce.current);
    upsellDebounce.current = setTimeout(() => {
      const upsellData = {
        cartProductId: selectedCartProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: selectedProductId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: true, // true/false, add or remove upsell
      };

      const prevUpsellData = {
        cartProductId: prevCartProductId, // upsell cart_ProductId
        cartId: item.cartId, // cartId
        productId: prevProductId,
        quantity: item.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
        isSelected: false, // true/false, add or remove upsell
      };

      const upsellForAdd = shippingUpsells.filter((upsell) => upsell.productId === upsellData.productId)[0];
      const upsellForRemoval = shippingUpsells.filter((upsell) => upsell.productId === prevUpsellData.productId)[0];

      // if selectedProductId, add selectedProductId
      // if selectedProductId and prevProductId, add selectedProductId and remove prevProductId
      // if !selectedProductId and prevProductId, remove prevProductId

      if (!selectedProductId && prevProductId) {
        API.updateUpsell(prevUpsellData).then((res) => {
          // console.log('cart', res.data);
          const cart = res.data;
          storeCart(cart);

          setIsUpdating(false);

          setSelectedUpsell((state) => ({
            ...state,
            isLoading: false,
          }));

          // push event and data to dataLayer
          ecomRemoveProduct(upsellForRemoval);
        });
      } else {
        API.updateUpsell(upsellData)
          .then((res) => {
            // console.log('cart', res.data);
            const cart = res.data;

            // push event and data to dataLayer
            ecomAddProduct(upsellForAdd);

            return cart;
          })
          .then((cartData) => {
            if (prevProductId) {
              API.updateUpsell(prevUpsellData).then((res) => {
                // console.log('cart', res.data);
                const cart = res.data;
                storeCart(cart);

                setIsUpdating(false);

                setSelectedUpsell((state) => ({
                  ...state,
                  isLoading: false,
                }));

                // push event and data to dataLayer
                ecomRemoveProduct(upsellForRemoval);
              });
            } else {
              storeCart(cartData);

              setIsUpdating(false);

              setSelectedUpsell((state) => ({
                ...state,
                isLoading: false,
              }));
            }
          })
          .catch((err) => {
            console.error(err?.response?.data);

            // show error message or reload the page
            cartDispatch({
              type: 'ERROR',
              payload: {
                isLoading: false,
                isError: true,
                error: err?.response?.data || 'General Error',
              },
            });

            if (err?.response?.data?.status >= 400) {
              console.error('Upsell was not updated!');
            }
          });
      }
    }, 0); // removing debouncing as workaround for wrong data UI/data combo issue
  };

  const shippingUpsellCheckboxes = shippingUpsells.map((upsell) => {
    const dispayPrice = getDisplayPrice(upsell, true);
    const origPrice = getOrigPrice(upsell, true);

    return (
      <div className="form-check" key={upsell.cart_ProductId}>
        {isUpdating && upsell.productId === currentUpsellId ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            value={upsell.productId}
            id={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}
            checked={upsell.productId === selectedUpsell.productId}
            onChange={handleChange}
            data-cart-product-id={upsell.cart_ProductId}
            data-upsell-type={upsell.upsellType}
          />
        )}

        <label className="form-check-label" htmlFor={`${prefix}upsell-cart-id-${upsell.cart_ProductId}`}>
          <span className="fw-600 d-block mb-1">
            <span className="upsell-name">{upsell.name}</span>{' '}
            <span className="upsell-price d-inline-block">
              (
              {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                <span className="orig-price me-2">+{currency(origPrice)}</span>
              )}
              +{currency(dispayPrice)})
            </span>
          </span>
          <span className="upsell-description d-block lh-15">{upsell.description}</span>
        </label>
      </div>
    );
  });

  return <form>{shippingUpsellCheckboxes}</form>;
}
