import { useEffect, useState, useMemo } from 'react';
import { parse } from 'node-html-parser';
import { BrandContext } from './BrandContext';
import API from '../services/apiService';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
BrandProvider.propTypes = {
  children: PropTypes.node,
};

let THEME_BASE_URL = 'https://dlthemes.amersc.com/shop/templates';

// const VITE_APP_MODE = import.meta.env.MODE;
const NODE_ENV = process.env.NODE_ENV.toLowerCase();
// const isDevMode = import.meta.env.DEV;

if (NODE_ENV === 'staging') {
  THEME_BASE_URL = THEME_BASE_URL.replace('dlthemes', 'mlthemes');
}
if (NODE_ENV === 'production') {
  THEME_BASE_URL = THEME_BASE_URL.replace('dlthemes', 'themes');
}

export default function BrandProvider({ children }) {
  const defaultBrand = useMemo(() => {
    const regex = /(dlshop|dlshop|shop|dlcart|mlcart|cart|dlstore|mlstore|store)\./;
    let domain = 'certus.com';

    // this is where the dynamic brand is determined using the hostname this app is being loaded from
    if (!window.location.hostname.includes('localhost')) {
      domain = window.location.hostname.replace(regex, '');
    }

    const brand = {
      domain,
      header: sessionStorage.getItem('header') || null,
      footer: sessionStorage.getItem('footer') || null,
      useFallback: false,
      cssFileUrl: `${THEME_BASE_URL}/${domain}/css/shop.css`,
      serverError: null,
    };
    return {
      ...brand,
    };
  }, []);

  const [brand, setBrand] = useState(defaultBrand);

  useEffect(() => {
    const fetchTemplate = async (domain) => {
      try {
        const resp = await API.getTemplate(domain);
        const data = await resp?.data;
        const parsedHtml = parse(data);

        const fetchedBrand = {
          ...defaultBrand,
          header: parsedHtml.querySelector('#header-wrap').outerHTML,
          footer: parsedHtml.querySelector('#footer-wrap').outerHTML,
        };

        sessionStorage.setItem('header', fetchedBrand.header);
        sessionStorage.setItem('footer', fetchedBrand.footer);

        setBrand((brand) => ({
          ...brand,
          ...fetchedBrand,
        }));
      } catch (error) {
        sessionStorage.removeItem('header');
        sessionStorage.removeItem('footer');

        // when the API call fails or if there's no existing template use the default certus.com template as the fallback
        const fallbackBrand = {
          ...defaultBrand,
          header: null,
          footer: null,
          useFallback: true,
          cssFileUrl: null,
          serverError: error,
        };

        setBrand((brand) => ({
          ...brand,
          ...fallbackBrand,
        }));
      }
    };

    fetchTemplate(defaultBrand.domain);
  }, [defaultBrand]);

  return <BrandContext.Provider value={brand}>{children}</BrandContext.Provider>;
}
