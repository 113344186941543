import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';
import { formatCurrency as currency } from '../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice, getUpsellTotal } from '../utils/amountHelper';
import { ecomRemoveProduct } from '../utils/ecomHelper';
import API from '../services/apiService';
import { CartContext } from '../store/CartContext';
import UpsellDisplay from './UpsellDisplay';
import BulkRatesModal from './BulkRatesModal';
import showFeesInCart from '../utils/showFeesInCart';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  isSingle: PropTypes.bool.isRequired,
};

export default function CartItem({ item, isSingle }) {
  const cartId = item.cartId;
  // console.log('cartItem', item);

  const showFees = showFeesInCart(item.productId);

  const [cartState, cartDispatch] = useContext(CartContext);

  const cartProductIds = useMemo(() => cartState.cartProducts.map((item) => item.productId), [cartState.cartProducts]);
  const displayUpsells =
    item.upSellProducts.filter((upsell) => upsell.isSelected || !cartProductIds.includes(upsell.productId)).length > 0;

  const [itemState, setItemState] = useState({
    isLoading: false,
    isRemovingItem: false,
    ...item,
  });

  // dont use upsell modal if there is no upsell
  useEffect(() => {
    if (item.upSellProducts.length === 0) {
      cartDispatch({
        type: 'EXPERIENCE',
        payload: {
          viewedUpsells: true,
        },
      });
    }
  }, [cartDispatch, item.upSellProducts.length]);

  const qtyDebounce = useRef();

  const itemDispayPrice = getDisplayPrice(item);

  const itemSubTotal =
    (showFees ? item.unitPriceWithDiscountAndFee : item.unitPriceWithDiscountAndFeeIfHidden) * item.quantity;

  const itemUpsellTotal = getUpsellTotal(item.upSellProducts);

  const itemTotal = itemSubTotal + itemUpsellTotal;

  const handleQtyChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;
    let quantity = itemState.quantity;

    if (name === 'quantity' && Number(theValue) > 0) {
      quantity = Number(theValue);
    }
    if (name === 'decrement' && itemState.quantity > 1) {
      quantity = quantity - 1;
    }
    if (name === 'increment') {
      quantity = quantity + 1;
    }

    if (itemState.quantity !== quantity) {
      setItemState((state) => ({
        ...state,
        quantity,
        isLoading: true,
      }));

      cartDispatch({
        type: 'UPDATE',
        payload: {
          isLoading: true,
        },
      });

      // simple debouncing to prevent unecessary API calls when a user continues to change quantity
      clearTimeout(qtyDebounce.current);
      qtyDebounce.current = setTimeout(() => {
        updateQty(quantity);
      }, 300);
    }
  };

  const updateQty = (qty) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isUpdatingCart: true,
      },
    });

    const product = {
      cartId: cartId,
      productId: item.productId,
      cartProductId: item.cart_ProductId,
      quantity: qty,
      isSelected: item.isSelected,
    };
    API.updateProduct(product)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isLoading: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const handleRemoveFromCart = () => {
    setItemState((state) => ({
      ...state,
      isRemovingItem: true,
    }));

    // console.log('cartProductId', cartProductId);
    cartDispatch({
      type: 'UPDATE',
      payload: {
        isLoading: true,
        isUpdatingCart: true,
      },
    });

    // actual API call that updates the cart
    API.removeProduct(cartId, item.cart_ProductId)
      .then(async (res) => {
        // update cart state globally
        let cart = res.data;

        setItemState((state) => ({
          ...state,
          isRemovingItem: false,
        }));

        // updating local cart with data from API
        cartDispatch({
          type: 'UPDATE',
          payload: {
            ...cart,
            isLoading: false,
            isUpdatingCart: false,
          },
        });

        ecomRemoveProduct(item);
      })
      .catch((err) => {
        console.error(err);
        console.error(err.response.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        // reload the page if the API call fails, ideally show an error message
        window.location.reload();
      });
  };

  const toggleFeeDescription = () => {
    // show fee description
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showFeeDescriptionFor: item.productId,
      },
    });
  };

  return (
    <div
      className={`card product mb-2 ${itemState.isRemovingItem ? 'is-removing-item' : ''}`}
      data-product-id={item.productId}
    >
      <div className="card-body d-flex flex-wrap justify-content-between py-2 py-lg-3 px-0">
        <div className="product-details">
          <h2 className="product-info h4">
            <span className="product-name me-2">{item.name}</span>
            <span className="price-display d-inline-block">
              (
              {item.discountSource && item.unitDiscountAmount > 0 && (
                <>
                  <span className="orig-price me-2">{currency(getOrigPrice(item))}</span>
                </>
              )}
              {currency(itemDispayPrice)})
            </span>
          </h2>
          {showFees && !item.hiddenFee && item.unitThirdPartyFee > 0 && (
            <p className="small text-muted mb-0">
              {item.thirdPartyFeeName}: {currency(item.unitThirdPartyFee)}{' '}
              <button
                className="btn-toggle fee-description-toggle fw-400 small text-reset lh-1 p-0 ms-1"
                type="button"
                onClick={toggleFeeDescription}
              >
                <span className="info-icon align-middle ms-0">?</span>
              </button>
            </p>
          )}

          {/* Render Upsell Groups */}
          {displayUpsells && <UpsellDisplay isSingle={isSingle} item={item} />}
        </div>
        <div className="product-qty text-center ms-auto ms-lg-0 mt-1">
          <button
            className="delete-btn btn text-muted"
            onClick={handleRemoveFromCart}
            type="button"
            name="delete"
            aria-label="Delete Item"
            disabled={cartState.isLoading || itemState.isRemovingItem}
          >
            {itemState.isRemovingItem ? (
              <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
            ) : (
              <DeleteIcon aria-hidden="true" />
            )}
          </button>

          {item.upsellType === 'Retake' ? (
            <input
              className="qty-input form-control text-center mx-auto"
              name="quantity"
              type="number"
              value={itemState.quantity}
              min="1"
              aria-label="Item Quantity"
              disabled={true}
              readOnly={true}
              style={{ height: '36px' }}
            />
          ) : (
            <div className={`input-group mb-1 ${cartState.isUpdatingCart ? 'is-loading' : ''}`}>
              <button
                className="qty-btn btn btn-sm btn-light"
                onClick={handleQtyChange}
                type="button"
                name="decrement"
                disabled={item.quantity === 1}
                aria-label="Decrement"
              >
                &#8722;
              </button>
              <input
                className="qty-input form-control"
                name="quantity"
                type="number"
                value={itemState.quantity}
                onChange={handleQtyChange}
                min="1"
                aria-label="Item Quantity"
              />
              <button
                className="qty-btn btn btn-sm btn-light"
                onClick={handleQtyChange}
                type="button"
                name="increment"
                aria-label="Increment"
              >
                &#43;
              </button>
            </div>
          )}

          {item.cartProductBulkTiers && item.cartProductBulkTiers.length > 0 && (
            <div className="qty-discount text-muted lh-1 mt-2 mb-1 mb-lg-0">
              <BulkRatesModal
                productId={item.cart_ProductId}
                productName={item.name}
                cartProductBulkTiers={item.cartProductBulkTiers}
              />
            </div>
          )}
        </div>
        <div className="product-price text-end mt-2">
          <span className="price-display">
            {itemState.isLoading || cartState.isPageShowing ? (
              <span className="animated-placeholder rounded-2 w-70 height-lg"></span>
            ) : (
              currency(itemTotal)
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
