import { useEffect, useContext, useCallback, useMemo } from 'react';
import { CartContext } from '../../store/CartContext';
import CheckboxItem from './CheckboxItem';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
CheckboxGroup.propTypes = {
  cartProductIds: PropTypes.array.isRequired,
  fieldType: PropTypes.string.isRequired,
  attestItems: PropTypes.array.isRequired,
  attestForm: PropTypes.object.isRequired,
  setAttestForm: PropTypes.func.isRequired,
};

export default function CheckboxGroup({ cartProductIds, fieldType, attestItems, attestForm, setAttestForm }) {
  const [cartState] = useContext(CartContext);

  const showItem = useCallback(
    (field) => {
      // check if one of the field productIds is in the cart
      const shouldValidate = field.productIds.some((productId) => cartProductIds.includes(productId));
      const isBulkOrder = field.productIds.some(
        (productId) => cartState.cartProducts.find((item) => item.productId === productId)?.quantity > 1
      );
      return field.onlyForBulk ? shouldValidate && isBulkOrder : shouldValidate;
    },
    [cartProductIds, cartState.cartProducts]
  );

  const orderedAttestItems = useMemo(
    () =>
      attestItems.sort(
        (itemA, itemB) =>
          cartProductIds.findIndex((id) => itemA.productIds.includes(id)) -
          cartProductIds.findIndex((id) => itemB.productIds.includes(id))
      ),
    [attestItems, cartProductIds]
  );

  const filteredAttestItems = useMemo(
    () =>
      cartProductIds
        .map((id) => orderedAttestItems.find((item) => item.productIds.includes(id)))
        .filter(Boolean)
        .map((field) => {
          const displayItem = showItem(field);

          if (displayItem) {
            return field;
          }
        })
        .filter(Boolean),
    [cartProductIds, orderedAttestItems, showItem]
  );

  // const checkboxNames = filteredAttestItems.map((item) => item.name);

  const checkboxNames = useMemo(() => filteredAttestItems.map((item) => item.name), [filteredAttestItems]);

  const displayGroup = filteredAttestItems.length > 0;

  // this adds all the checkboxes info into the parent AttestationForm component's state
  useEffect(() => {
    const sessionAttestForm = sessionStorage.getItem('attestForm') && JSON.parse(sessionStorage.getItem('attestForm'));

    setAttestForm((state) => {
      // collecting all the checkboxes so that we can them as form fields
      const checkboxItems = filteredAttestItems.reduce((acc, cur) => {
        acc[cur.name] = {
          value: sessionAttestForm?.fields[cur.name]
            ? sessionAttestForm?.fields[cur.name].value
            : state.fields[cur.name]
            ? state.fields[cur.name].value
            : false,
          isValid: sessionAttestForm?.fields[cur.name]
            ? sessionAttestForm?.fields[cur.name].isValid
            : state.fields[cur.name]
            ? state.fields[cur.name].isValid
            : false,
        };
        return acc;
      }, {});

      const isGroupValid = Object.values(checkboxItems).every((item) => item?.isValid);

      return {
        ...state,
        fields: {
          ...state.fields,
          [fieldType]: {
            value: !displayGroup ? true : isGroupValid,
            isValid: !displayGroup ? true : isGroupValid,
          },
          ...checkboxItems,
        },
      };
    });
  }, [displayGroup, fieldType, setAttestForm, filteredAttestItems]);

  // handle error collection then setting the attestationForm state
  useEffect(() => {
    const isGroupValid = attestForm.fields[fieldType]?.isValid || false;

    setAttestForm((state) => {
      const errorCollection = { ...state.errors };

      for (const item of orderedAttestItems) {
        const displayItem = showItem(item);

        const isItemValid = attestForm.fields[item.name]?.isValid;

        if (!displayItem || isGroupValid || isItemValid) {
          // removing the current field's error if attestation is not displayed
          // or if the grouped field have is valid
          delete errorCollection[item.name];
        } else {
          errorCollection[item.name] = item.errorMsg; // current field's errorMsg
        }
      }

      return {
        ...state,
        errors: errorCollection,
      };
    });
  }, [attestForm.fields, fieldType, orderedAttestItems, setAttestForm, showItem]);

  // this is ONLY for the checkboxes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const theValue = type === 'checkbox' ? checked : value;

    setAttestForm((state) => {
      // setting value manually here so that I can check latest state before updating
      state.fields[name].isValid = theValue;

      if (theValue) {
        delete state.errors[name];
      }

      // console.log('state', state);

      // checking if all the checkboxes are checked and valid
      const isGroupValid = checkboxNames.every((name) => state.fields[name].isValid);

      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: {
            value: theValue,
            isValid: theValue,
          },
          // setting the grouped field's value and validity by using all the item's validity
          [fieldType]: {
            value: isGroupValid,
            isValid: isGroupValid,
          },
        },
        touched: {
          ...state.touched,
          [name]: true,
        },
      };
    });
  };

  const handleModalClose = (fieldName) => {
    setAttestForm((state) => {
      // removing error message for this field
      delete state.errors[fieldName];

      return {
        ...state,
        fields: {
          ...state.fields,
          [fieldName]: {
            value: true,
            isValid: true,
          },
        },
        errors: state.errors,
      };
    });
  };

  const attestationList = filteredAttestItems?.map((item) => (
    <CheckboxItem
      key={item.name}
      cartState={cartState}
      attestForm={attestForm}
      attestItem={item}
      handleModalClose={handleModalClose}
      handleChange={handleChange}
      fieldType={fieldType}
    />
  ));

  if (displayGroup) {
    return (
      <>
        <div
          className={`card attestation-group ${
            (attestForm.touched[fieldType] || cartState.validation.formTouched) && !attestForm.fields[fieldType]?.isValid
              ? 'bg-warning bg-opacity-10 border-danger-subtle'
              : 'bg-warning bg-opacity-10 border-warning-subtle'
          }  my-3`}
        >
          <div className="card-body">{attestationList}</div>
        </div>
      </>
    );
  }

  return null;
}
