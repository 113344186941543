import { useState, useContext } from 'react';
import { formatCurrency as currency } from '../../utils/formatCurrency';
import { getDisplayPrice, getOrigPrice } from '../../utils/amountHelper';
import { ecomAddProduct, ecomRemoveProduct } from '../../utils/ecomHelper';
import API from '../../services/apiService';
import { CartContext } from '../../store/CartContext';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
RelatedUpsellItem.propTypes = {
  upsell: PropTypes.object.isRequired,
};

export default function RelatedUpsellItem({ upsell }) {
  const [cartState, cartDispatch] = useContext(CartContext);

  const isUpsellTypeReplace = upsell.upsellType === 'Replace';
  const isSingle = cartState.cartProducts.length === 1;
  const singleProductItem = cartState.cartProducts[0];

  const priceDisplay =
    upsell.discountSource === 'Upsell' ? getDisplayPrice(upsell) + upsell.unitDiscountAmount : getDisplayPrice(upsell);
  const origPriceDisplay = getOrigPrice(upsell);

  const upsellDispayPrice = getDisplayPrice(upsell, true);
  const origPrice = getOrigPrice(upsell, true);

  const origPriceDisplaySingle = isUpsellTypeReplace
    ? origPrice -
      (!upsell.hiddenFee
        ? singleProductItem.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : singleProductItem.unitPriceWithDiscountAndFeeIfHidden)
    : origPrice;

  const priceDisplaySingle = isUpsellTypeReplace
    ? upsellDispayPrice -
      (!upsell.hiddenFee
        ? singleProductItem.unitPriceWithDiscountAndFeeIfHidden + upsell.unitThirdPartyFee
        : singleProductItem.unitPriceWithDiscountAndFeeIfHidden)
    : upsellDispayPrice;

  const [isChecked, setIschecked] = useState(upsell.isSelected);
  const [isUpdating, setIsUpdating] = useState(false);

  const storeCart = (cartData) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        ...cartData,
        isLoading: false,
        isUpdatingCart: false,
      },
    });

    // trigger to show the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showNotification: true,
      },
    });
  };

  const addToCart = () => {
    setIsUpdating(true);

    cartDispatch({
      type: 'UPDATE',
      payload: {
        isLoading: true,
        isUpdatingCart: true,
      },
    });

    // trigger to hide the toast notification
    cartDispatch({
      type: 'EXPERIENCE',
      payload: {
        showNotification: false,
      },
    });

    const cartData = {
      cartId: cartState.cartId,
      productIds: [upsell.productId],
      pricingVariant: upsell.pricingUsed,
      groupId: upsell.groupId,
      coupon: upsell.discountCode,
      sourceHost: cartState.sourceHost,
    };
    API.updateCart(cartData)
      .then(async (res) => {
        const cartData = res.data;
        storeCart(cartData);

        ecomAddProduct(upsell);
      })
      .catch((err) => {
        console.error(err?.response?.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isPageShowing: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        if (err?.response?.data?.status >= 400) {
          console.error('Related Upsell was not added to cart!');
        }
      });
  };

  const storeCartProducts = (updatedCartProducts) => {
    cartDispatch({
      type: 'UPDATE',
      payload: {
        cartProducts: updatedCartProducts,
        isLoading: true,
      },
    });
  };

  // this is only for single-product cart
  const addUpsell = () => {
    setIsUpdating(true);

    setIschecked(!isChecked);

    const updatedCartProducts = cartState?.cartProducts?.map((product) => {
      if (product.cart_ProductId === singleProductItem.cart_ProductId) {
        const upsells = product.upSellProducts;
        const updatedUpsells = upsells.map((productUpsell) => {
          if (productUpsell.cart_ProductId === upsell.cart_ProductId) {
            if (productUpsell.upsellType === 'Replace') {
              productUpsell.subTotal = productUpsell.subTotal - singleProductItem.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFee =
                productUpsell.unitPriceWithDiscountAndFee - singleProductItem.unitPriceWithDiscountAndFeeIfHidden;
              productUpsell.unitPriceWithDiscountAndFeeIfHidden =
                productUpsell.unitPriceWithDiscountAndFeeIfHidden - singleProductItem.unitPriceWithDiscountAndFeeIfHidden;
            }
            // console.log('Update Upsell', {
            //   ...productUpsell,
            //   isSelected: !isChecked,
            // });
            return {
              ...productUpsell,
              isSelected: !isChecked,
            };
          }
          return productUpsell;
        });
        return {
          ...product,
          upSellProducts: updatedUpsells,
        };
      }
      return product;
    });

    storeCartProducts(updatedCartProducts);

    cartDispatch({
      type: 'UPDATE',
      payload: {
        isLoading: true,
      },
    });

    const upsellData = {
      cartProductId: upsell.cart_ProductId, // upsell cart_ProductId
      cartId: upsell.cartId, // cartId
      productId: upsell.productId,
      quantity: upsell.quantity, // use quantity of main product or you can leave 1, it will automatically use the main product's quantity
      isSelected: !isChecked, // true/false, add or remove upsell
    };

    API.updateUpsell(upsellData)
      .then((res) => {
        // console.log('cart', res);
        const cart = res.data;
        setIsUpdating(false);
        storeCart(cart);

        // push event and data to dataLayer
        if (upsellData.isSelected) {
          ecomAddProduct(upsell);
        } else {
          ecomRemoveProduct(upsell);
        }
      })
      .catch((err) => {
        console.error(err?.response?.data);

        // show error message or reload the page
        cartDispatch({
          type: 'ERROR',
          payload: {
            isLoading: false,
            isError: true,
            error: err?.response?.data || 'General Error',
          },
        });

        if (err?.response?.data?.status >= 400) {
          console.error('Upsell was not updated!');
        }
      });
  };

  const handleChange = () => {
    if (isSingle) {
      addUpsell();
    } else {
      addToCart();
    }
  };

  // if (!isSingle && isChecked) {
  //   return null;
  // }

  return (
    <div
      className={`upsell-item rounded ${cartState.isLoading ? 'is-loading' : ''}`}
      data-upsell-type={upsell.upsellType}
      data-product-id={upsell.productId}
    >
      <div className="form-check">
        {isUpdating ? (
          <span className="spinner-border spinner-border-sm upsell-check" role="status" aria-hidden="true"></span>
        ) : (
          <input
            className="form-check-input"
            type="checkbox"
            value={upsell.productId}
            id={`related-upsell-cart-id-${upsell.cart_ProductId}`}
            checked={isSingle ? isChecked : false}
            onChange={handleChange}
            data-cart-product-id={upsell.cart_ProductId}
            data-upsell-type={upsell.upsellType}
          />
        )}

        {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
        <label className="form-check-label d-block" htmlFor={`related-upsell-cart-id-${upsell.cart_ProductId}`}>
          <span className="fw-600 d-block d-sm-flex justify-content-between mb-1">
            <span className="upsell-name">{upsell.name}</span>{' '}
            <span className="upsell-price d-inline-block text-end">
              <span className="d-sm-none">(</span>
              {isSingle ? (
                <>
                  {upsell.discountSource && upsell.discountSource !== 'BuiltIn' && (
                    <span className="orig-price me-2">+{currency(origPriceDisplaySingle)}</span>
                  )}
                  +{currency(priceDisplaySingle)}
                </>
              ) : (
                <>
                  {upsell.discountSource && upsell.discountSource !== 'Upsell' && (
                    <span className="orig-price me-2">+{currency(origPriceDisplay)}</span>
                  )}
                  +{currency(priceDisplay)}
                </>
              )}

              <span className="d-sm-none">)</span>
            </span>
          </span>
          <span className="upsell-description d-block lh-15">{upsell.description}</span>
        </label>
      </div>
    </div>
  );
}
