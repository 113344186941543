// the domain key is the trigger if we can show modal
// aside from the domain we also check the products in the cart\
// if domain and productIds = show the modal upon user exit intent

export const exitModalData = {
  // 'certus.com': {
  //   coupon: 'NYSC5OFF',
  //   headline: 'Leaving so soon?',
  //   discount: 'Take $5 Off',
  //   subCopy: 'Click Continue to apply the discount and proceed with your purchase.',
  //   productIds: ['563b6496-0351-4bc8-b9fd-a624d9c38bde', 'dc333352-3f52-437d-9f33-fec1ad7f49ff'], // use to determine if we can show the modal
  // },
  'newyorksafetycouncil.com': {
    coupon: 'NYSC5OFF',
    headline: 'Leaving so soon?',
    discount: 'Take $5 Off',
    subCopy: 'Click Continue to apply the discount and proceed with your purchase.',
    productIds: ['563b6496-0351-4bc8-b9fd-a624d9c38bde', 'dc333352-3f52-437d-9f33-fec1ad7f49ff'], // use to determine if we can show the modal
  },
};
