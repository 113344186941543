import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import useBrand from '../hooks/useBrand';
import cookies from '../utils/cookies';

export default function CartEmpty() {
  const { domain } = useBrand();

  useEffect(() => {
    cookies.delete('cartId');
    cookies.delete('bulkCartId');
  }, []);

  return (
    <>
      <Helmet>
        <title>Cart Details</title>
      </Helmet>

      <div className="container-fluid but-fixed">
        <div className="row justify-content-center">
          <div className="col-12 text-center py-3 pt-md-4 py-lg-5">
            <h1 className="fw-300 mb-4">Your Shopping Cart Is Empty</h1>
            <p>To continue shopping please click the button below.</p>
            <p>
              <a className="btn btn-outline-secondary" href={`https://${domain}`}>
                Continue Shopping
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
