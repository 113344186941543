// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'flMatureDriver', // this used as the field name as well as the unique identifier
    productIds: [
      'acbec60b-4086-4f8b-9ac7-d7c5e18900bc', // Florida Mature Driver
      'be09fed2-e1d8-481d-3f84-08dc4824bf0e', // for QA using the Design QA Product, only in ML
    ],
    errorMsg: `You must agree with the <strong>Florida Mature Driver</strong> terms by typing "<strong>I agree</strong>" in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'iAgree', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb({ handleModal }) {
  return (
    <>
      To purchase <strong>Florida Mature Driver course</strong>, please type &quot;<strong>I agree</strong>&quot; in the box
      below to attest that you have read, understand, and{' '}
      <a href="#" onClick={handleModal}>
        accept the terms
      </a>
      .
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return (
    <>
      <h3 className="modal-title fw-600 mb-3">Florida Mature Driver Course Terms</h3>
      <p>All students need to agree to the following statements in order to enroll in the course:</p>
      <ol>
        <li className="mb-3">The student who registers for this course is the one that takes the course.</li>
        <li className="mb-3">
          The student must take a test of at least 40 questions on course content and get 80% correct to receive a course
          completion certificate. Failure to correctly answer 80% content questions will result in a course failure and the
          course fee will not be refunded.
        </li>
        <li className="mb-3">
          The student will be asked time limited validation questions during the course. The validation questions will ask
          them questions about themselves and specific events that happened in the course. Failure to answer a time limited
          validation question correctly will result in failure of the course and the course fee will not be refunded.
        </li>
        <li className="mb-3">
          The course provider will determine how many times a student can attempt to answer questions correctly.
        </li>
        <li className="mb-3">
          The course provider will determine what constitutes a course failure and provide the Department with the criteria
          for failure.
        </li>
      </ol>
    </>
  );
}
