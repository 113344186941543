import { Helmet } from 'react-helmet-async';

// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
ErrorBoundary.propTypes = {
  fromPage: PropTypes.string.isRequired,
};

export default function ErrorBoundary({ fromPage }) {
  return (
    <>
      <Helmet>
        <title>Something Went Wrong...</title>
        {/* <link rel="canonical" href={`https://www.${domain}`} /> */}
      </Helmet>

      <div className="container-fluid but-fixed">
        <div className="row justify-content-center">
          <div className="col-12 pt-3 pt-md-4">
            <h3>Something Went Wrong...</h3>
            <p>Error on: {fromPage}</p>
          </div>
        </div>
      </div>
    </>
  );
}
