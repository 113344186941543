import useBrand from '../hooks/useBrand';
import parse from 'html-react-parser';
import certusLogo from '../assets/certus-logo-primary.svg';
import fusionLogo from '../assets/fusion-logo-primary.svg';

// breadcrumb.scss is going to be loaded from the shop themes CDN
// import '../scss/breadcrumb.scss';

export default function Header() {
  const { header, useFallback } = useBrand();

  if (header) {
    return parse(header);
  }

  // fallback default header
  if (useFallback) {
    return (
      <header id="header-wrap" className="clearfix">
        <div className="container-fluid but-fixed">
          <div className="row">
            <div id="header" className="col-sm-12">
              <a className="logo my-2 my-md-3 float-start d-flex align-items-center border-0" href="https://www.certus.com/">
                <img src={certusLogo} alt="Certus" width="250" height="50" loading="lazy" />
              </a>
              <div className="brand float-end d-none d-sm-block mt-2 mt-md-3">
                <div className="desktop-brand mt-2 mt-md-2">
                  <span className="powered-by d-inline-block align-middle mb-0 me-2">Powered By:</span>
                  <img
                    className="certus-platform-logo img-fluid align-middle"
                    src={fusionLogo}
                    alt="Fusion Platform"
                    width="120"
                    height="33"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="heading-wrap py-2 py-md-4 border-top border-bottom">
          <div className="container-fluid but-fixed">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="step-wrap">
                  <span className="step step-cart">Cart Details</span>
                  <span className="step step-checkout">Checkout</span>
                  <span className="step step-receipt">Confirmation</span>
                  <span className="step step-registration">Registration</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }

  // skeleton header
  return (
    <header id="header-wrap" className="clearfix border-bottom">
      <div className="container-fluid but-fixed">
        <div className="row">
          <div id="header" className="col-sm-12">
            <div className="my-2 my-md-3 float-start d-flex" style={{ width: '100%', maxWidth: '300px' }}>
              <span className="animated-placeholder rounded-2" style={{ width: '100%', height: '50px' }}></span>
            </div>

            <div className="brand float-end d-none d-sm-block mt-2 mt-md-3">
              <div className="desktop-brand mt-2 mt-md-2" style={{ height: '33px' }}>
                <span className="powered-by d-inline-block align-middle mb-0 me-2">Powered By:</span>
                <span className="d-inline-block align-middle">
                  <span className="animated-placeholder rounded-2 d-block" style={{ width: '120px', height: '33px' }}></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
