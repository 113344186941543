// helper function to check we we need to show the fees in the cart
// list of productIds that needs to show the fees in the cart

const hideUpsellInCart = (productId, testPrice) => {
  const productIds = [
    '801edc26-4f24-4993-3121-08dcf8db6a48', // AAA Membership ACA
    '67a7144a-aa6c-4801-3122-08dcf8db6a48', //AAA Membership ACG
    '904f3634-e67b-475a-311d-08dcf8db6a48', // design test upsell
  ];

  return productIds.includes(productId) && testPrice.toUpperCase() === 'B';
};

export default hideUpsellInCart;
