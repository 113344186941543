// ESlint complains about prop-types, you need to define it
import PropTypes from 'prop-types';
Blurb.propTypes = {
  handleModal: PropTypes.func.isRequired,
};

// this are the details for this attestation field
export function Config() {
  return {
    name: 'designTestProduct', // this used as the field name as well as the unique identifier
    productIds: [
      '3d203b3c-d1f7-4e8e-bb3f-08dbe08d871c', // Design Test Product - HiddenFee=true
    ],
    errorMsg: `You must agree with the <strong>Design Test Product</strong> terms by typing your full legal name in the highlighted box.`,
    onlyForBulk: false, // set to true if this attestation is only for bulk purchase
    type: 'signature', // iAgree, signature, checkbox
  };
}

// this is the blurb we display to tell the user what this attestation field is for
export function Blurb({ handleModal }) {
  return (
    <>
      To purchase <strong>Design Test Product</strong>, enter your <strong>full legal name</strong> below to attest that you
      have read, understand, and{' '}
      <a href="#" onClick={handleModal}>
        accept the terms
      </a>
      .
    </>
  );
}

// this is the explaination of the attestation, the full details of what the user is aggreeing to
export function ModalContent() {
  return (
    <>
      <h3 className="modal-title fw-600 mb-3">Design Test Product Terms</h3>
      <p>All students need to agree to the following statements in order to enroll in the course:</p>
      <ol>
        <li className="mb-3">The student who registers for this course is the one that takes the course.</li>
        <li className="mb-3">
          The course provider will determine how many times a student can attempt to answer questions correctly.
        </li>
        <li className="mb-3">And so on, this is just an example...</li>
      </ol>
    </>
  );
}
